<template>
  <div class="page">
    <img class="logo" :src="appConfig.app.imgPath + 'image/common/logo_name.svg'" />
    <a-form
        class="form"
        layout="vertical"
        :model="formState"
    >
      <a-form-item>
        <a-input v-model:value="formState.signInName" placeholder="登录名" size="large" @pressEnter="onSignInClick">
          <template #prefix>
            <UserOutlined style="color: rgba(0, 0, 0, 0.25)"/>
          </template>
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input v-model:value="formState.password" type="password" placeholder="密码" size="large"
                 @pressEnter="onSignInClick">
          <template #prefix>
            <LockOutlined style="color: rgba(0, 0, 0, 0.25)"/>
          </template>
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-button
            type="primary"
            block
            size="large"
            :disabled="formState.user === '' || formState.password === ''"
            @click="onSignInClick"
        >
          登录
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import {UserOutlined, LockOutlined} from '@ant-design/icons-vue';
import {notification} from 'ant-design-vue';
import {defineComponent, reactive, onBeforeMount} from 'vue';
import {useRouter} from 'vue-router';
import useSign from "@/hook/useSign";
import {urlMap} from "@/utils/uriMap";
import appConfig from "@/app.config";

export default defineComponent({
  name: 'signIn',
  setup() {
    const {signIn, checkSignStatus} = useSign();
    const router = useRouter();
    const formState = reactive({
      mode: 20,
      signInRole: '103',
      signInName: '',
      password: '',
    });

    onBeforeMount(() => {
      checkSignStatus(true)
      .then(res => {
        if (res.isSigned) {
          router.replace(urlMap.user);
        }
      })
    })

    const onSignInClick = () => {
      signIn(formState)
          .then(res => {
            notification.destroy();
            if (res.status === 200) {
              router.replace(urlMap.user);
            } else {
              notification.error({message: res.msg});
            }
          })
          .catch(err => {
            console.log(err);
            notification.destroy();
            notification.error({message: '未知错误'});
          })
    }

    return {
      appConfig,
      formState,
      onSignInClick,
    };
  },

  components: {
    UserOutlined,
    LockOutlined,
  },
});
</script>

<style lang="less" scoped>
.page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .form {
    width: 300px;
  }

  .ant-form-item {
    margin-bottom: 12px;
  }
}

.logo {
  width: 300px;
  margin-bottom: 36px;
}
</style>